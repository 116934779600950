import axios from 'axios';
import { API } from '../api';
import axiosInstance from '../axiosConfig';
import { useAuthStore } from '../../zustand-store/auth.store';
import { createBrowserRouter } from 'react-router-dom';

export const loginApiDev = async ({ email, password }) => {
  const response = await axiosInstance.post(
    API.AUTH_DEV.LOGIN,
    { email, password },
    { withCredentials: true }
  );
  const refreshToken = response.data.data.refreshToken;
  const accessToken = response.data.data.accessToken;
  localStorage.setItem('token', refreshToken);
  // tokenService.setToken(accessToken);
  useAuthStore.getState().setAccessToken(accessToken);
  return response.data.data.message === 'success';
};

export const loginApiProd = async ({ email, password }) => {
  const response = await axiosInstance.post(
    API.AUTH.LOGIN,
    { email, password },
    { withCredentials: true }
  );
  // return to login screen and redirection to calendar in that case if cred didnt match
  if (!response.data?.data?.accessToken) {
    return response;
  }
  const accessToken = response.data?.data?.accessToken;
  useAuthStore.getState().setAccessToken(accessToken);
  return response.data.data.message === 'success';
};

export const refreshTokenDev = async () => {
  const BASE_URL = import.meta.env.VITE_BASE_URL_BACKEND;
  const response = await axios.post(
    BASE_URL + API.AUTH_DEV.REFRESH + localStorage.getItem('token'),
    { withCredentials: true }
  );

  console.log(response);
  const accessToken = response.data.data.accessToken;
  const refreshToken = response.data.data.refreshToken;
  localStorage.setItem('token', refreshToken);
  // tokenService.clearToken();
  // tokenService.setToken(accessToken);

  await useAuthStore.getState().setAccessToken(accessToken);

  return accessToken;
};

export const refreshTokenProd = async () => {
  const BASE_URL = import.meta.env.VITE_BASE_URL_BACKEND;
  const response = await axios.post(BASE_URL + API.AUTH.REFRESH, {}, { withCredentials: true });
  console.log(response);
  const accessToken = response.data.data.accessToken;
  // tokenService.clearToken();
  // tokenService.setToken(accessToken);

  await useAuthStore.getState().setAccessToken(accessToken);
  return accessToken;
};

export const logoutDev = async () => {
  localStorage.removeItem('token');
  //navigate to login page
};

export const logoutProd = async () => {
  //navigate the user to login page
  const BASE_URL = import.meta.env.VITE_BASE_URL_BACKEND;
  await axios.get(BASE_URL + API.AUTH.LOGOUT, { withCredentials: true });
  const router = createBrowserRouter(['/login']);
  router.navigate('/login');
};
