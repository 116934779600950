export const API = {
  AUTH_DEV: {
    LOGIN: '/dev/login',
    REFRESH: '/dev/refresh/'
  },
  AUTH: {
    LOGIN: '/user/login',
    REFRESH: '/user/refresh',
    LOGOUT: '/user/logout'
  },
  LOCATION: {
    FIND_BY_ID: '/location/findById/',
    ADD_LOCATION: '/location/add',
    UPDATE_LOCATION: '/location/update'
  },
  customer_appointment: {
    ALL_LOCATIONS: '/location/findAll',
    AVALIABLE_SLOTS_BY_DATE: 'appointment/getAvaliableSlotsByDate',
    CREATE_APPOINMNET: '/appointment/public/create',
    CREATE_APPOINMNET_BOOKINGID: '/appointment/public/take-payment',
    APPOINMNET_SUMMARY: '/appointment/public/appointmentSummary/',
    CREATE_APPOINMENT_FROM_DAHBOARD: '/appointment/add',
    UPDATE_APPOINMENT_FROM_DAHBOARD: '/appointment/update',
    APPOINTMENT_WITHOUT_PAYMENT: '/appointment/public/create/no-payment'
  },
  WORKING_DAYS: {
    ALL_WORKING_DAYS: 'appointment/getAvaliableDates/',
    WORKING_DAYS_BY_LOCATION: '/working-day/findAllByLocation/',
    UPDATE_WORKING_DAYS_BY_LOCATION: '/working-day/update'
  },
  SERVICES: {
    ALL_SERVICES: 'service/findAllByLocation/',
    ADD_SERVICE: '/service/add',
    DELETE_SERVICE: '/service/delete/',
    UPDATE_SERVICE: '/service/update',
    UPDATE_STATUS: '/service/update',
    ALL_SERVICE_WITHOUT_FILTER: 'service/findByLocation/'
  },
  PATIENTS: {
    ADD_PATIENT: '/customer/add',
    GET_PATIENT: '/customer/findAll/',
    GET_PATIENT_BY_ID: '/customer/findById/',
    UPDATE_PATIENT: '/customer/update',
    DELETE_PATIENT: '/customer/delete/',
    TEMP_PATIENT: '',
    PAGINATION_PATIENTS: '/customer/findAllWithPagination',
    PAGINATION_SEARCH: '/customer/searchWithPagination'
  },
  TIMEOFFS: {
    ADD: '/time-off/add',
    UPDATE: 'time-off/update',
    DELETE: 'time-off/delete/',
    GET_ALL_BY_LOCATION: '/time-off/findAll/'
  },
  OAUTH: {
    CREATE_SQUARE_PAYMENT_ACCOUNT: '/payment-accounts/add',
    GET_ALL_SQUARE_PAY_LOCATION: '/square/findAllSquareLocations/',
    BIND_SQUARE_LOCATION: '/payment-accounts/bind-square-location/',
    CHECK_SQUARE_CONNECTED: '/payment-accounts/isSquareConnected/',
    DISCONNECT_SQUARE: '/payment-accounts/deleteSquareAccount/'
  },
  BREAKS: {
    GET_ALL_BREAKS: '/break/findAll/',
    ADD_BREAK: '/break/add',
    REMOVE_BREAK: '/break/delete/'
  },
  APPOINTMENT: {
    GET_APPOINTMENT_BY_LOCATION: 'appointment/findAllByLocation/',
    CHANGE_APPOINTMENT_STATUS: '/appointment/change-status/',
    CANCEL_APPOINTMENT: '/appointment/cancel/',
    PAGINATION_APPOINTMENTS: '/appointment/getAllByLocationWithPagination',
    PAGINATION_APPOINTMENTS_SEARCH: '/appointment/searchWithPagination',
    GET_APPOINTMENT_BY_YEAR_MONTH: '/appointment/findAllByYearMonth/',
    DELETE: '/appointment/delete/'
  },
  GTM: {
    GET_GTM_ID: 'google-tag-manager/findByLocation/',
    ADD_GTM_ID: 'google-tag-manager/create',
    UPDATE_GTM_ID: 'google-tag-manager/edit'
  },
  ADMIN: {
    ADD_ADMIN: '/user/register/admin',
    GET_ALL_ADMIN: '/user/findAllAdminsByLocation/',
    DELETE_ADMIN: '/user/delete/',
    CHANGE_APPOINTMENT_STATUS: '/appointment/change-status/',
    CANCEL_APPOINTMENT: '/appointment/cancel/'
  },
  PAYEMNT_TRANSACTIONS: {
    GET_ALL_PAYMENTS: '/paymentTransaction/getAllByLocation/',
    PAGINATION_PAYEMNTS: '/paymentTransaction/getAllByLocationWithPagination'
  },
  REFUND: {
    CLAIM_REFUND: '/refund/issue',
    REFUNDS_LIST: '/refund/findAllByPaymentTransaction/'
  },
  BOOKING_POLICIES: {
    GET_POLICIES: '/location/findById/',
    ADD_POLICIES: '/location/changeCancellationPolicy',
    ADD_WINDOW_SIZE: '/location/changeScheduleWidowSize'
  },
  NOTIFICATIONS: {
    GET_ALL_NOTFICATIONS: '/notifications/findAllByLocation/',
    MARK_ALL_NOTIFICATIONS_READ: '/notifications/markAllRead'
  },
  CANCELLATION: {
    GET_BYLOCATION: '/location/findCancellationPolicy/'
  },
  PROFILE: {
    GET: '/user/profile/',
    UPDATE: '/user/updateProfile'
  }
};
