import React, { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const Spinners = ({ setLoading }) => {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [setLoading]);
  return (
    <div
      className="d-flex justify-content-center align-items-center vh-100 vw-100 position-fixed top-0 start-0  z-3"
      style={{ background: 'rgba(0,0,0,.5)' }}
    >
      <Spinner color="light" />
      <p className="m-0 text-light ms-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>
        {' '}
        Loading ...
      </p>
    </div>
  );
};

Spinners.propTypes = {
  setLoading: PropTypes.func.isRequired
};

export default Spinners;
