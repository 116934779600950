import {
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
  GET_ACCESS_TOKEN
} from './actionTypes';

// export const loginUser = (user, dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const data = await post(import.meta.env.VITE_APP_ENV === 'dev' ? DEV_LOGIN : LOGIN, user, {
//         withCredentials: import.meta.env.VITE_APP_ENV === 'dev' ? false : true
//       });

//       const accessToken =
//         import.meta.env.VITE_APP_ENV === 'dev' ? data?.data?.accessToken : data?.data;
//       dispatch(set_access_token(accessToken));

//       if (import.meta.env.VITE_APP_ENV === 'dev') {
//         localStorage.setItem('rftoken', data?.data?.refreshToken);
//       }

//       resolve(data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const refreshToken = (dispatch) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const data = await post(
//         import.meta.env.VITE_APP_ENV === 'dev'
//           ? `${DEV_REFRESH_TOKEN}/${localStorage.getItem('rftoken')}`
//           : REFRESH_TOKEN,
//         {},
//         { withCredentials: import.meta.env.VITE_APP_ENV === 'dev' ? false : true }
//       );
//       const accessToken =
//         import.meta.env.VITE_APP_ENV === 'dev' ? data?.data?.data?.accessToken : data?.data?.data;
//       dispatch(set_access_token(accessToken));

//       if (import.meta.env.VITE_APP_ENV === 'dev') {
//         localStorage.setItem('rftoken', data?.data?.data?.refreshToken);
//       }

//       resolve(data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

export const set_access_token = (token) => {
  return {
    type: GET_ACCESS_TOKEN,
    payload: token
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history }
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error
  };
};

export const socialLogin = (type, history) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { type, history }
  };
};
