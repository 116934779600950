import { API } from '../api';
import axiosInstance from '../axiosConfig';

export const getProfile = async (id) => {
  const response = await axiosInstance.get(API.PROFILE.GET + id);
  return response;
};
export const updateProfile = async (body) => {
  const response = await axiosInstance.patch(API.PROFILE.UPDATE, body);
  return response;
};
