import axiosInstance from '../axiosConfig';
import { API } from '../api';
import { useCustomerAppointmentStore } from '../../zustand-store/customerAppointment.store';
export const getAllLocations = async () => {
  const response = await axiosInstance.get(API.customer_appointment.ALL_LOCATIONS);
  useCustomerAppointmentStore.getState().setAllLocations(response?.data?.data);
  return response?.data?.data;
};

export const getAllWorkingDays = async (id) => {
  const response = await axiosInstance.get(API.WORKING_DAYS.ALL_WORKING_DAYS + id);
  return response?.data?.data;
};

export const getAllServices = async (id) => {
  const response = await axiosInstance.get(API.SERVICES.ALL_SERVICES + id);
  useCustomerAppointmentStore.getState().setAllAppointments(response?.data?.data);
  return response?.data?.data;
};

export const getAllServicesWithoutFilter = async (id) => {
  const response = await axiosInstance.get(API.SERVICES.ALL_SERVICE_WITHOUT_FILTER + id);
  useCustomerAppointmentStore.getState().setAllAppointments(response?.data?.data);
  return response?.data?.data;
};

export const getAvaliableSlotsByDate = async (payload) => {
  const response = await axiosInstance.post(
    API.customer_appointment.AVALIABLE_SLOTS_BY_DATE,
    payload
  );
  return response?.data?.data;
};

export const createAppoinment = async (payload) => {
  const response = await axiosInstance.post(API.customer_appointment.CREATE_APPOINMNET, payload);
  return response;
};

export const createAppoinmentWithBookingId = async (payload) => {
  const response = await axiosInstance.post(
    API.customer_appointment.CREATE_APPOINMNET_BOOKINGID,
    payload
  );
  return response;
};

export const getAppoinmentSummary = async (id) => {
  const response = await axiosInstance.get(API.customer_appointment.APPOINMNET_SUMMARY + id);
  return response;
};
export const createAppoinmentFromDashboard = async (payload) => {
  const response = await axiosInstance.post(
    API.customer_appointment.CREATE_APPOINMENT_FROM_DAHBOARD,
    payload
  );
  return response;
};
export const updateAppoinmentFromDashboard = async (payload) => {
  const response = await axiosInstance.patch(
    API.customer_appointment.UPDATE_APPOINMENT_FROM_DAHBOARD,
    payload
  );
  return response;
};

export const makeAppointmentWithoutPayment = async (payload) => {
  const response = await axiosInstance.post(
    API.customer_appointment.APPOINTMENT_WITHOUT_PAYMENT,
    payload
  );
  return response?.data?.data;
};
