import { Navigate } from 'react-router-dom';
import React from 'react';
import { useAuthStore } from '../zustand-store/auth.store';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ children, roles }) => {
  const role = useAuthStore((state) => state.role);
  const accessToken = useAuthStore((state) => state.accessToken);

  const userHasRequiredRole = roles.includes(role);

  if (accessToken === '' && role === '') {
    return <Navigate to={'/login'} />;
  }

  if (!userHasRequiredRole) {
    return 'Not Found'; // build your won access denied page (sth like 404)
  }

  return children;
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired
};
