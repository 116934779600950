// saga.js
import { call, put, takeEvery } from 'redux-saga/effects';
import { FETCH_TODOS_REQUEST } from './actionTypes';
import { fetchTodosSuccess, fetchTodosFailure } from './actions';

function* fetchTodosRequest({ payload: { user, history } }) {
  console.log(user, history, '===');
  try {
    const response = yield call(fetch, 'https://jsonplaceholder.typicode.com/todos', {
      method: 'GET', // or 'POST', 'PUT', etc.
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer your_token_here' // Add other headers as needed
      }
    });
    const todos = yield response.json();

    yield put(fetchTodosSuccess(todos));
  } catch (error) {
    yield put(fetchTodosFailure(error.message));
  }
}

export default function* GeneralSaga() {
  yield takeEvery(FETCH_TODOS_REQUEST, fetchTodosRequest);
}
