import {
  CHANGE_LOCATION,
  CHANGE_APPOINTMENT,
  TO_DO,
  FETCH_TODOS_REQUEST,
  FETCH_TODOS_SUCCESS,
  FETCH_TODOS_FAILURE
} from './actionTypes';
export const courseList = () => {
  // return new Promise(async (resolve, reject) => {
  //   try {
  //     const data = get('/todos');
  //     resolve(data);
  //   } catch (error) {
  //     console.log(error, 'error');
  //     reject(error);
  //   }
  // });
};
export const changeLocation = (location) => ({
  type: CHANGE_LOCATION,
  payload: location
});
export const changeAppointment = (location) => ({
  type: CHANGE_APPOINTMENT,
  payload: location
});
export const toDo = () => ({
  type: TO_DO
});

export const fetchTodosRequest = (user, history) => ({
  type: FETCH_TODOS_REQUEST,
  payload: { user, history }
});

export const fetchTodosSuccess = (todos) => ({
  type: FETCH_TODOS_SUCCESS,
  payload: todos
});

export const fetchTodosFailure = (error) => ({
  type: FETCH_TODOS_FAILURE,
  payload: error
});
