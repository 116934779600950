import { API } from '../api';
import axiosInstance from '../axiosConfig';

export const getNotificationByLocations = async (id) => {
  const response = await axiosInstance.get(`${API.NOTIFICATIONS.GET_ALL_NOTFICATIONS}${id}`);
  return response.data;
};
export const markAllNotifications = async (body) => {
  const response = await axiosInstance.post(API.NOTIFICATIONS.MARK_ALL_NOTIFICATIONS_READ, body);
  return response?.data;
};
