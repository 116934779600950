import { API } from '../api';
import axiosInstance from '../axiosConfig';
export const getSquarePayLocations = async (locationId) => {
  const response = await axiosInstance.get(API.OAUTH.GET_ALL_SQUARE_PAY_LOCATION + locationId);
  return response.data?.data;
};
export const createPaymentAccount = async (body) => {
  const response = await axiosInstance.post(API.OAUTH.CREATE_SQUARE_PAYMENT_ACCOUNT, body);
  return response.data;
};
export const bindSquareLocation = async (id, locationId) => {
  const response = await axiosInstance.get(API.OAUTH.BIND_SQUARE_LOCATION + id + '/' + locationId);
  return response;
};
export const isSquareConnected = async (locationId) => {
  const response = await axiosInstance.get(API.OAUTH.CHECK_SQUARE_CONNECTED + locationId);
  return response;
};
export const disconnectSquarePay = async (locationId) => {
  const response = await axiosInstance.delete(API.OAUTH.DISCONNECT_SQUARE + locationId);
  return response;
};
