import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { useAuthStore } from '../../zustand-store/auth.store';
import {
  getGtmId,
  addGtmInLocation,
  updateGtmInLocation
} from '../../axios/googleTagManager/index.api';
import { toast } from 'react-toastify';
const DetailModal = ({ isOpen, toggle, title, description, icon }) => {
  const [gtmId, setGtmId] = useState('GTM-'); // Initial value starts with "GTM-"
  const [updateCheck, setUpdateCheck] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [loader, setLoader] = useState(false);
  const handleChange = (e) => {
    // Prevent removal of "GTM-" and allow typing only after it
    const value = e.target.value;
    if (value.startsWith('GTM-')) {
      setGtmId(value);
    }
  };
  const locationId = useAuthStore((state) => state.locationId);
  useEffect(() => {
    if (isOpen) {
      getInitialGtm();
    }
  }, [locationId, isOpen]);
  const getInitialGtm = async () => {
    let response = await getGtmId(locationId);
    console.log(response, '=');
    if (response?.data?.message == 'Account Not Linked') {
      setGtmId('GTM-');
      setUpdateCheck(false);
      setSelectedLocationId(null);
    } else {
      setUpdateCheck(true);
      setGtmId(response?.data?.data?.containerId);
      setSelectedLocationId(response?.data?.data?.id);
    }
  };
  const saveGTM = async () => {
    setLoader(true);
    let responese = await addGtmInLocation({
      locationId: parseInt(locationId),
      containerId: gtmId
    });
    if (responese?.data?.data) {
      toast.success(responese?.data?.data);
      setUpdateCheck(true);
      toggle();
    }
    setLoader(false);
  };
  const updateGTM = async () => {
    setLoader(true);
    let responese = await updateGtmInLocation({
      locationId: parseInt(locationId),
      containerId: gtmId,
      id: parseInt(selectedLocationId)
    });
    if (responese?.data?.data) {
      toast.success(responese?.data?.data);
      setUpdateCheck(true);
      toggle();
    }
    setLoader(false);
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} className="bg-light px-4 py-4">
        <div className="d-flex align-items-center mt-3">
          {/* Align image to the left */}
          <img
            style={{
              width: '66px',
              height: '64px',
              marginRight: '20px',
              background: 'rgb(240,240,240)',
              borderRadius: '50%'
            }}
            src={icon}
            alt="icon"
            className="p-2 "
          />
          <div>
            {/* Title above description */}
            <h5 className="mb-0">
              {title} {locationId}
            </h5>
            <small className="text-muted">{description}</small>
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <ol>
          <li>
            <p>Open a new browser tab and log into GTM. Click ‘Create account’.</p>
          </li>
          <li>
            <p>Complete the account and container setup form, then click ‘Create’.</p>
          </li>
          <li>
            <p>
              Copy the GTM container ID and return to Setmore. Paste the ID in the form field
              provided and save your changes.
            </p>
          </li>
        </ol>
        <div className="mx-3">
          <input
            name="gtm-id"
            placeholder="Your GTM container ID"
            className="form-control"
            value={gtmId}
            onChange={handleChange}
          />
          <p>ex: GTM-xxxxxxx</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={updateCheck ? updateGTM : saveGTM} disabled={loader}>
          {loader ? (
            <>
              <Spinner size="sm" /> {/* Small spinner when loading */}
              {' Loading...'}
            </>
          ) : updateCheck ? (
            'Update'
          ) : (
            'Save'
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any
};

export default DetailModal;
