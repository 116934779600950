import { create } from 'zustand';
import { refreshTokenDev, refreshTokenProd } from '../axios/login/login.api';
import { jwtDecode } from 'jwt-decode';

export const useAuthStore = create((set) => ({
  id: 0,
  accessToken: '',
  refreshToken: '',
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  locationId: localStorage.getItem('locationId') ? localStorage.getItem('locationId') : null,

  setAccessToken: (accessToken) =>
    set((state) => {
      const { id, firstName, lastName, email, role, locationId } = jwtDecode(accessToken);
      return {
        ...state,
        accessToken,
        role,
        id,
        firstName,
        lastName,
        email,
        locationId: localStorage.getItem('locationId')
          ? localStorage.getItem('locationId')
          : locationId
      };
    }),
  setRefreshToken: (refreshToken) =>
    set((state) => {
      return {
        ...state,
        refreshToken
      };
    }),
  refreshAllTheToken: () =>
    set(async (state) => {
      const accessToken =
        import.meta.env.VITE_APP_ENV === 'dev' ? await refreshTokenDev() : await refreshTokenProd();
      const { id, firstName, lastName, email, role, locationId } = jwtDecode(accessToken);

      return {
        ...state,
        accessToken,
        role,
        id,
        firstName,
        lastName,
        email,
        locationId: localStorage.getItem('locationId')
          ? localStorage.getItem('locationId')
          : locationId
      };
    }),
  setLocationId: (locationId) =>
    set((state) => {
      return {
        ...state,
        locationId: locationId
      };
    })
}));
