import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import Spinners from './components/Common/Spinner';
import ErrorBoundary from './components/Common/ErrorBoundries';
import * as Sentry from '@sentry/react';
Sentry.init({
  dsn: 'https://701f3a2129a6dbbd5f8491d0e99bb630@o4508380101148672.ingest.de.sentry.io/4508403887571024',
  integrations: [],
  environment: import.meta.env.VITE_APP_ENV || 'staging'
});
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense fallback={<Spinners setLoading={() => {}} />}>
        <ErrorBoundary>
          {' '}
          {/* Wrap the ErrorBoundary around your components */}
          <React.Fragment>
            <Provider store={store}>
              <App />
            </Provider>
          </React.Fragment>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
);

// serviceWorker.unregister()
