import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '../Common/withRouter';

//i18n
import { withTranslation } from 'react-i18next';
import SidebarContent from './SidebarContent';

import { Link } from 'react-router-dom';

import SeamlessLogo from '../../assets/images/logov1.1.png';
import Loader from '../Common/Loader';

const Sidebar = (props) => {
  const loading = false;

  // refresh token decoded so logout user
  // useEffect(() => {
  //   if (getToken()) {
  //     let expiryCheck: any = jwtDecode(getToken());

  //     if (Date.now() >= expiryCheck.exp * 1000) {
  //       logout()
  //     }
  //   }
  //   return () => { }

  // }, [])

  // access token decoded so refresh token api hit

  // when page reloads hit access token api

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/calender" className="logo logo-dark">
              <span className="logo-sm">{/* <img src={logo} alt="" height="22" /> */}</span>
              <span className="logo-lg">
                <img src={SeamlessLogo} alt="" height="20" />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">{/* <img src={logoLightSvg} alt="" height="22" /> */}</span>
              <span className="logo-lg">{/* <img src={logoLightPng} alt="" height="19" /> */}</span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
          </div>

          <div className="sidebar-background"></div>
        </div>
      )}
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout
  };
};
export default connect(mapStatetoProps, {})(withRouter(withTranslation()(Sidebar)));
