import React, { useState } from 'react';
import { Card, CardBody, CardText } from 'reactstrap';
import PropTypes from 'prop-types';
import DetailModal from './googletagMangerDetailModal';
const GoogleTagManagerCard = ({ title, description, icon }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <>
      <Card
        style={{ border: '1px solid', color: '#EFF2F7', cursor: 'pointer', height: 220 }}
        onClick={toggleModal} // open modal on card click
      >
        <CardBody>
          <div className="d-flex justify-content-between">
            <img style={{ width: '30px', height: '30px' }} src={icon} alt="" />
          </div>
          <p style={{ fontSize: '20px', color: '#495057' }} className="mt-2">
            {title}
          </p>
          <CardText style={{ color: '#74788D' }}>{description}</CardText>
        </CardBody>
      </Card>
      {/* Modal Component */}
      <DetailModal
        isOpen={modalOpen}
        toggle={toggleModal}
        title={title}
        icon={icon}
        description={description}
      />
    </>
  );
};

GoogleTagManagerCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isIntegrated: PropTypes.string,
  icon: PropTypes.any // replace 'any' with the actual prop type
};

export default GoogleTagManagerCard;
