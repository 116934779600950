import { toast } from 'react-toastify';
import * as jwt from 'jwt-decode'; // import dependency

export function convertToISODateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date-time string');
  }

  return date.toISOString();
}

export function trimSentence(sentence, num) {
  if (sentence.length > num) {
    return sentence.slice(0, num) + '...';
  } else {
    return sentence;
  }
}

export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const weekday = weekdays[date.getDay()];

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const period = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12;

  const startTime = `${hours}.${Math.floor(minutes / 15) * 15}${period}`;
  const endTime = `${hours}.${(Math.floor((minutes + 30) / 15) * 15) % 60}${period}`;

  // Construct the final formatted string
  return `${day} ${month}, ${weekday} | ${startTime} - ${endTime}`;
}

export function formatDate(dateString) {
  // Create a new Date object from the input date string
  const date = new Date(dateString);

  // Define an array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  // Extract the day, month, and year from the date object
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  // Format the date as "DD MMM YYYY"
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}

export const errorResponse = async (error) => {
  console.log(error, 'error fnction');
  if (error.response) {
    toast.error(error.response.data.message);
  } else if (error.request) {
    toast.error('Network Error');
  } else {
    toast.error('Something Went Wrong');
  }
};

export const getUser = (token) => {
  if (token) {
    console.log(token);
    const user = jwt.jwtDecode(token);
    console.log(user, 'get user cookie');
    return user;
  }
};
