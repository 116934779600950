import React from 'react';
// import BackLogo from '../../assets/images/back.png';
// import { useNavigate } from 'react-router-dom';
const TermsAndCondition = () => {
  //meta title
  document.title = 'Terms and Conditions';
  // const navigate = useNavigate();

  return (
    <div className="m-5">
      {/* <div
        className="d-flex flex-row align-items-center my-md-4 my-sm-4"
        onClick={() => {
          navigate(-1);
        }}>
        <img src={BackLogo} />
        <p style={{ color: '#000' }} className="m-1">
          Go back
        </p>
      </div> */}
      <p className="fs-1 fw-bold text-dark">Terms and Conditions – Seamless Slot</p>
      <p className="fs-4 text-dark">
        Welcome to the Seamless Slot website {`(collectively referred to as the "Site")`}, which
        includes all content, functionality, mobile applications, software, and services provided by
        Seamless Slot. By accessing or using the Site, you {`("User")`}, whether registered or not,
        agree to these Terms and Conditions {`("Agreement")`}, including our Privacy Policy and any
        applicable addenda. By using this Site, you also agree to comply with all applicable laws
        and regulations.
      </p>
      <p className="fs-1 text-dark">Use of the Site</p>
      <p className="fs-4 text-dark">
        Seamless Slot is not intended for individuals under the age of 13. By registering for an
        account, Users confirm they are at least 18 years old and legally permitted to enter binding
        agreements. The Site may not be used to gain a competitive advantage in the gaming industry
        or for unauthorized commercial purposes.
      </p>
      <p className="fs-4 text-dark">
        Users are solely responsible for the content they upload, post, or share on the Site (“User
        Submissions”). By submitting content, Users grant Seamless Slot a non-exclusive,
        royalty-free, perpetual license to use, display, reproduce, and distribute this content in
        any format or media. User Submissions are not considered confidential or proprietary.
      </p>
      <p className="fs-1 text-dark">Third-Party Integrations</p>
      <p className="fs-4 text-dark">
        Seamless Slot integrates with third-party platforms to enhance functionality. Examples
        include payment processors and analytics tools. These third parties operate under their own
        Terms and Privacy Policies. Seamless Slot is not liable for issues arising from third-party
        integrations, including but not limited to data breaches or disputes. Users are advised to
        familiarize themselves with the terms of these third-party services.
      </p>

      <p className="fs-1 text-dark">Subscription Fees</p>
      <p className="fs-4 text-dark">
        Seamless Slot offers both free and paid subscription plans. By opting for a paid
        subscription, Users agree to pay all applicable fees as outlined on the Site. Subscriptions
        automatically renew unless canceled before the renewal date. Refunds are not provided for
        unused portions of subscription periods. For cancellations or downgrades, please contact
        support at support@seamlessslot.com.
      </p>
      <p className="fs-1 text-dark">Electronic Communications</p>
      <p className="fs-4 text-dark">
        By using the Site, Users consent to receive electronic communications from Seamless Slot,
        including transactional notifications, account updates, and promotional messages. Users may
        opt out of non-essential communications by following the instructions in the Privacy Policy.
      </p>
      <p className="fs-1 text-dark">Third-Party Links and Content</p>
      <p className="fs-4 text-dark">
        By using Seamless Slot’s platform or registering for our services, you consent to receive
        electronic communications from us. These communications may include essential messages
        related to:
        <ul>
          <li>Updates to your account settings.</li>
          <li>Booking notifications, such as new, modified, or canceled appointments.</li>
          <li>Payment confirmations, reminders, or other transactional details.</li>
        </ul>
        Additionally, Seamless Slot may send you educational or promotional updates about new
        features, services, or offers relevant to you. While these messages may not be operationally
        essential, you can opt-out at any time.
      </p>

      <p className="fs-1 text-dark">Rules of Conduct</p>
      <p className="fs-4 text-dark">
        The following rules of conduct apply to your use of the Seamless Slot platform and all User
        Submissions posted on the platform. Users agree to adhere to generally accepted rules of
        etiquette and behavior standards. Additionally, as a user, you agree not to:
        <ul>
          <li>
            Post User Submissions that are libelous, hateful, inflammatory, illegal, inaccurate,
            malicious, false, pornographic, defamatory, harmful to others, obscene, sexually
            explicit, harassing, vulgar, violent, racially, ethnically, or culturally offensive, or
            otherwise objectionable or offensive in any way.
          </li>
          <li>
            Post User Submissions that infringe on privacy, publicity, intellectual property,
            proprietary, or contractual rights.
          </li>
          <li>Post photos or videos of another person without that {`person's`} consent.</li>
          <li>
            Post any advertising, solicitation, or commercial content unrelated to the platform.
          </li>
          <li>
            Post User Submissions or take actions that violate laws, create liability or promote
            illegal activities.
          </li>
          <li>Impersonate any person or entity or misrepresent your identity or affiliations.</li>
          <li>
            Promote software or services that deliver unsolicited emails or contain viruses, Trojan
            horses, worms, time bombs, or harmful programming routines.
          </li>
          <li>
            Engage in systematic retrieval of content from the platform to create a database or
            directory via robots, spiders, or manual processes.
          </li>
          <li>Solicit personal information from anyone under the age of 18.</li>
          <li>
            Share the personal information of another person without their explicit permission.
            <li>Use another user’s account or alter/remove their submissions without consent.</li>
          </li>
        </ul>
        Seamless Slot does not prescreen User Submissions and cannot guarantee the absence of
        offensive, inaccurate, or unlawful content. Seamless Slot expressly disclaims liability
        related to User Submissions and assumes no obligation to monitor or remove such content.
        However, we reserve the right to:
        <ul>
          <li>Restrict platform access for any user.</li>
          <li>Edit, remove, or refuse to post User Submissions.</li>
          <li>
            Terminate accounts for any reason, including submissions harmful to others or harassment
            of Seamless Slot’s service providers.
          </li>
        </ul>
        Users are encouraged to report objectionable content, but Seamless Slot does not guarantee
        the removal or editing of any specific submission.
      </p>
      <p className="fs-1 text-dark">Accuracy of Information</p>
      <p className="fs-4 text-dark">
        Seamless Slot strives for accuracy in all content and pricing. However, errors may occur,
        and Seamless Slot reserves the right to correct these at any time, including during
        transactions. Prices and promotions are subject to change without notice.
      </p>
      <p className="fs-1 text-dark">Termination of Service</p>
      <p className="fs-4 text-dark">
        Seamless Slot reserves the right to terminate access to the Site or User accounts at its
        sole discretion, with or without notice. Provisions regarding indemnity, intellectual
        property rights, and limitation of liability will survive termination.
      </p>

      <p className="fs-1 text-dark">Indemnity</p>
      <p className="fs-4 text-dark">
        Users agree to indemnify Seamless Slot against any claims, losses, or damages arising from
        their use of the Site, violations of this Agreement, or infringement of third party rights.
      </p>

      <p className="fs-1 text-dark">Intellectual Property</p>
      <p className="fs-4 text-dark">
        All content on the Site, including logos, designs, text, and software, is the property of
        Seamless Slot and protected under applicable laws. Unauthorized use of intellectual property
        is strictly prohibited.
      </p>

      <p className="fs-1 text-dark">Termination Policy</p>
      <p className="fs-4 text-dark">
        Seamless Slot holds the right to terminate user accounts or restrict access to its services
        at its discretion, with or without notice, for any necessary reason. Key provisions such as
        Termination, Indemnity, Copyright and Trademark Disclaimer, Limitation of Liability,
        Disclaimer of Warranties, and Governing Law remain enforceable even after service
        termination.
      </p>

      <p className="fs-1 text-dark">Indemnification</p>
      <p className="fs-4 text-dark">
        By using Seamless Slot, users agree to indemnify and defend the platform, its affiliates,
        and service providers against all claims, liabilities, damages, and costs, including
        reasonable attorney fees, arising from:
        <ul>
          <li>Violations of Seamless Slot’s policies.</li>
          <li>Breaches of applicable laws.</li>
          <li>Third-party claims due to user activity, submissions, or account usage.</li>
        </ul>
      </p>

      <p className="fs-1 text-dark">Copyright and Trademark Disclaimer</p>
      <p className="fs-4 text-dark">
        The Seamless Slot platform is owned and operated by Seamless Slot and is provided for both
        personal and commercial purposes. The Seamless Slot name, logo, and all platform
        content—including images, text, graphics, icons, designs, buttons, code, and software—are
        protected by trademark, copyright, and applicable intellectual property laws.
      </p>
      <p className="fs-4 text-dark">
        Third-party trademarks, product names, and logos featured on the platform remain the
        property of their respective owners. Seamless Slot holds exclusive rights to its trademarks
        and strictly prohibits unauthorized use, reproduction, modification, or distribution.
        Written permission from Seamless Slot is required for any use of its trademarks beyond
        personal, non-commercial purposes.
      </p>
      <p className="fs-4 text-dark">
        Users may electronically copy or print content from the platform solely for non-commercial
        use related to placing personal bookings or managing appointments through Seamless Slot.
        Users agree to adhere to intellectual property laws and refrain from using any platform
        materials to create derivative works or for unauthorized commercial activities.
      </p>
      <p className="fs-4 text-dark">
        For user-generated content posted to Seamless Slot, users warrant that they hold the rights
        to such submissions. Users agree not to post, modify, distribute, or reproduce content owned
        by another party without obtaining permission from the rightful owner.
      </p>

      <p className="fs-1 text-dark">Limitation of Liability and Disclaimer of Warranties</p>
      <p className="fs-4 text-dark">
        Seamless Slot provides its services and platform on an {`"as is"`} and {`"as available"`}{' '}
        basis, without any express or implied warranties, including but not limited to
        merchantability, fitness for a particular purpose, or service continuity. Seamless Slot does
        not guarantee that the platform or its servers are free of viruses or harmful code. Seamless
        Slot, its affiliates, and service providers are not liable for:
        <ul>
          <li>Service interruptions, errors, or delays.</li>
          <li>Loss of data, profits, or business opportunities.</li>
          <li>Security breaches, privacy violations, or unauthorized access.</li>
          <li>Malfunctions caused by third-party integrations or user negligence.</li>
        </ul>
        <p>
          In jurisdictions where limitations on liability for incidental or consequential damages
          are restricted, Seamless {`Slot's`} liability will be limited to the maximum extent
          allowed by law.
        </p>
        <p>
          Users agree to indemnify and hold Seamless Slot harmless against any claims, damages, or
          losses arising from the use of the platform. In no event will Seamless {`Slot's`}{' '}
          liability exceed the amount paid by the user in the six months preceding the claim.
        </p>
        <p>
          Seamless Slot reserves the right to modify or discontinue any part of its platform or
          services without notice.
        </p>
      </p>
      <p className="fs-1 text-dark">Privacy</p>
      <p className="fs-4 text-dark">
        Seamless Slot prioritizes user privacy and compliance with applicable data protection
        regulations. However, the platform is not currently HIPAA-compliant. Users are responsible
        for ensuring compliance with HIPAA, HITECH, GDPR, and other relevant privacy laws when using
        the platform.
      </p>
      <p className="fs-4 text-dark">
        Personal data collected by Seamless Slot may include information about users, their
        personnel, and third parties acting on their behalf. Seamless Slot processes this data by
        its Privacy Policy, which outlines practices for data collection, storage, use, and
        transfer. Users consent to these practices and agree to ensure that their personnel are
        aware of and consent to the processing of personal data.
      </p>
      <p className="fs-4 text-dark">
        Users represent and warrant that they are entitled under applicable laws to share personal
        data with Seamless Slot for platform usage. Users also acknowledge that personal data may be
        transferred to and processed in jurisdictions outside their own, where data protection laws
        may differ.
      </p>
    </div>
  );
};
export default TermsAndCondition;
