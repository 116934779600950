// @flow
import {
  CHANGE_LOCATION,
  CHANGE_APPOINTMENT,
  TO_DO,
  FETCH_TODOS_REQUEST,
  FETCH_TODOS_SUCCESS,
  FETCH_TODOS_FAILURE
} from './actionTypes';

//constants

const INIT_STATE = {
  location: [
    { name: '51-53 Spring Hill,Birmingham B18 7BH, UK' },
    { name: '52-53 Spring Hill,Birmingham B18 7BH, UK' },
    { name: '53-53 Spring Hill,Birmingham B18 7BH, UK' }
  ],
  selectedLocation: 0,
  appointmentTypes: [
    {
      type: 'Emergency Dental Consultation | Adult',
      time: '30min',
      cost: '£60'
    },
    {
      type: 'Emergency Dental Consultation | Child',
      time: '30min',
      cost: '£60'
    }
  ],
  selectedAppointment: 0,
  countryCodes: [
    { name: 'UK', code: '+44' },
    { name: 'PK', code: '+92' },
    { name: 'UK', code: '+44' }
  ],
  loading: false,
  todos: [],
  error: null
};

const General = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCATION:
      return {
        ...state,
        selectedLocation: action.payload
      };
    case CHANGE_APPOINTMENT:
      return {
        ...state,
        selectedAppointment: action.payload
      };
    case TO_DO:
      return {
        ...state
      };
    case FETCH_TODOS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_TODOS_SUCCESS:
      return {
        ...state,
        loading: false,
        todos: action.payload
      };
    case FETCH_TODOS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default General;
